// import bootstrap js from application.js
import { Tab } from 'bootstrap';
// Code for previous and next buttons
$(document).on('turbolinks:load', function () {

  // on click #start-button, add display: none #start-tab and display: block #form-tab
  $('#start-button').on('click', function () {
    $('#start-tab').addClass('d-none');
    // remove inline style from #form-tab
    $('#form-tab').removeAttr('style');

  });

  // when radio.form-check-input is checked, find parent .btn-radio and add .active
  // $('.form-check-input').on('click', function () {
  //   $(this).parent('.btn-radio').addClass('active');
  // });

  var tabs = $('.nav-tabs .nav-link');
  var activeTab = $('.nav-tabs .nav-link.active');
  const nextBtn = $('#nextTab');
  const prevBtn = $('#previousTab');
  const submitBtn = $('#submitBtn');

  if (tabs.index(activeTab) === tabs.length - 1) {
    nextBtn.prop('disabled', true);
    submitBtn.hide();
  }

  if (tabs.index(activeTab) === 0) {
    //prevBtn.prop('disabled', true);
    prevBtn.hide();
  }

  nextBtn.on("click", function () {
    var activeIndex = tabs.index(activeTab);
    tabs = $('.nav-tabs .nav-link');

    const nextTabEl = tabs[activeIndex + 1]

    var nextTab = new Tab(nextTabEl)
    nextTab.show()


    activeTab = nextTabEl

    //prevBtn.prop('disabled', false);
    prevBtn.show();

    if (tabs.index(activeTab) === tabs.length - 1) {

      nextBtn.hide();
      submitBtn.show();
    }
  });

  prevBtn.on("click", function() {
    var activeIndex = tabs.index(activeTab);
    tabs = $('.nav-tabs .nav-link');

    const previousTabEl = tabs[activeIndex - 1]
    var previousTab = new bootstrap.Tab(previousTabEl)
    previousTab.show()

    activeTab = previousTabEl

    nextBtn.prop('disabled', false);
    nextBtn.show();

    if (tabs.index(activeTab) === 0) {
      prevBtn.prop('disabled', true);
      prevBtn.hide();
    }
    if (tabs.index(activeTab) !== tabs.length - 1) {
      submitBtn.hide();
    }
  });

  // disable submitBtn until valid email address is entered in #submitBtn
  $('#project_email').on('keyup', function () {
    var email = $("#project_email").val();
    if(email != 0) {
      if(isValidEmailAddress(email)) {
        submitBtn.prop('disabled', false);
      } else {
        submitBtn.prop('disabled', true);
      }
    }
  });

  // on click of nextBtn or prevBtn, jump to top of page
  prevBtn.on('click', function () {
    window.scrollTo(0, 0);
  });
  nextBtn.on('click', function () {
    window.scrollTo(0, 0);
  });
});

function isValidEmailAddress(emailAddress) {
  var pattern = /^(.)*@(.)*?$/;
  return pattern.test(emailAddress);
}

// Code for filling out the summary tab
$(document).on('turbolinks:load', function () {
  const nextBtn = $('#nextTab');
  var tabs = $('.nav-tabs .nav-link');

  nextBtn.on("click", function () {
    var activeTab = $('.nav-tabs .nav-link.active');
    tabs = $('.nav-tabs .nav-link');

    if (tabs.index(activeTab) === tabs.length - 1) {
      $("#project-type-summary").html("<strong> Project Type: </strong>" + $("#project-type-select").find("option:selected").text());

      $("#platform-summary").html("<strong> Platform: </strong>" + $("#project-platform-select").find("option:selected").text());

      $("#audience-summary").html("<strong> Audience: </strong>" + $("#project-audience-select").find("option:selected").text());

      $("#business-type-summary").html("<strong> Business Type: </strong>" + $("#project-business-type-select").find("option:selected").text());

      $("#feature-scope-summary").html("<strong> Features: </strong>");
      $(".project-feature-select").each(function () {
        $("#feature-scope-summary").append($(this).find("option:selected").text().split('|').shift().trim() + ", ");
      });
      $("#feature-scope-summary").html($("#feature-scope-summary").html().slice(0, -2));

      $("#geographic-availability-summary").html("<strong> Geographic Availability: </strong>" + $("#project-geographic-availability-select").find("option:selected").text());

      $("#translation-summary").html("<strong> Translation: </strong>" + $("#project-translation-select").find("option:selected").text());
    }
  });
});

// Code for chaning dropdown options based on previous selections
$(document).on('turbolinks:load', function () {
  updatePlatformTab();

  $('#project-type-select').on('change', function () {
    updatePlatformTab();
  });

  function updatePlatformTab() {
    var selectedValue = $('#project-type-select').val();
    let platformSelect = $('#project-platform-select');

    if (selectedValue === "web_application") {
      $('#platform-tab').removeClass('nav-link');
      $('#platform-tab').hide();
    }
    else {
      $('#platform-tab').hasClass('nav-link') ? null : $('#platform-tab').addClass('nav-link')
      $('#platform-tab').show();
    }

    if (selectedValue === "mobile_application") {
      platformSelect.val(window.mobile_platforms[0])
      platformSelect.find("option").each(function () {
        if (window.mobile_platforms.includes($(this).val())) {
          $(this).prop('disabled', false);
        }
        else {
          $(this).prop('disabled', true);
        }
      });
    }
    else if (selectedValue === "desktop_application") {
      platformSelect.val(window.desktop_platforms[0])
      platformSelect.find("option").each(function() {
        if (window.desktop_platforms.includes($(this).val())) {
          $(this).prop('disabled', false);
        }
        else {
          $(this).prop('disabled', true);
        }
      });
    }
  }
});
