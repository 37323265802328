// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import * as bootstrap from 'bootstrap';
import 'popper.js/dist/popper'
import './toasts'
import 'bootstrap-icons/font/bootstrap-icons.css'

global.$ = require('jquery')
global.jQuery = global.$

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("./price_calculator")
require("jquery")
require("@nathanvda/cocoon")
require("@popperjs/core")


global.bootstrap = bootstrap;